import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne, offsetRelativeToAncestor } from 'Helpers/dom/dom';
import { LOCALE } from 'Helpers/env/locale';
import { isDesktop } from 'Helpers/viewport/viewport';

import { SharedPageController } from 'Pararius/Page/Page';

const PARENT_NAME = 'page';
const NAME = 'listing-detail-page';

const CLASSES = {
    'sidebar': renderClasses(PARENT_NAME, 'sidebar'),
    'rowListing': renderClasses(PARENT_NAME, 'row', ['listing']),
    'listingMapInline': renderClasses(PARENT_NAME, 'wrapper', ['map']),
    'notifications': renderClasses(PARENT_NAME, 'wrapper', ['notifications']),
};

const SELECTORS = {
    'sidebar': `.${CLASSES.sidebar}`,
    'rowListing': `.${CLASSES.rowListing}`,
    'listingMapInline': `.${CLASSES.listingMapInline}`,
    'notifications': `.${CLASSES.notifications}`,
};

const ATTRS = {
    'type': 'type',
};

const MESSAGE_HANDLERS = [
    'autocompleteItemSelected.masthead',
    'notificationHidden.all',
];

const SPACING = 16;

class ListingDetailPage extends SharedPageController {
    initialize () {
        this.bindMethodsToSelf([
            'kickOffScrollRaf',
            'kickOffResizeRaf',
            'scheduleDesktopScrollRaf',
            'scheduleMobileScrollRaf',
            'scheduleResizeRaf',
            'updateMeasurements',
            'onAutocompleteItemSelected',
        ]);

        super.initialize();
    }

    connect () {
        super.connect();

        this.sidebar = findOne(SELECTORS.sidebar, this.element);
        this.listingMapInline = findOne(SELECTORS.listingMapInline, this.element);
        this.rowListing = findOne(SELECTORS.rowListing, this.element);

        this.updateMeasurements();

        window.addEventListener('scroll', this.kickOffScrollRaf);
        window.addEventListener('resize', this.kickOffResizeRaf);
    }

    async onAutocompleteItemSelected (evt) {
        // If more pages are going to include the autocomplete, we need to make this part of the page controller.
        const filters = evt.data.data.location;
        filters.type = this.data.get(ATTRS.type);

        Object.keys(filters).forEach(key => {
            if (!filters[key]) {
                delete filters[key];
            }
        });

        // If city and province both have values, strip out province so it doesn't get priority over the city value
        // and the user gets redirected to the province search page instead of the city search page
        if (filters.city && filters.province) {
            delete filters.province;
        }

        const formData = new FormData();

        Object.keys(filters).forEach(key => {
            formData.append(`filters[${key}]`, filters[key]);
        });

        formData.append('view_options[view]', 'list');

        const response = await fetch(Platform.uris.get_search_urls, {
            'headers': {
                'X-Requested-With': 'XMLHttpRequest',
            },
            'body': formData,
            'method': 'POST',
        });

        const data = await response.json();

        location.href = `${data.urls.list[LOCALE]}`;
    }

    onNotificationHidden () {
        const notificationWrapper = findOne(SELECTORS.notifications);

        notificationWrapper.parentNode.removeChild(notificationWrapper);
    }

    kickOffScrollRaf () {
        if (!this.scrollRaf) {
            if (isDesktop()) {
                this.scrollRaf = requestAnimationFrame(this.scheduleDesktopScrollRaf);
            } else {
                this.scrollRaf = requestAnimationFrame(this.scheduleMobileScrollRaf);
            }
        }

        clearTimeout(this.scrollRafTimeout);
        this.scrollRafTimeout = setTimeout(() => {
            cancelAnimationFrame(this.scrollRaf);
            this.scrollRaf = null;
        }, 250);
    }

    scheduleDesktopScrollRaf () {
        this.updateMeasurements();
        this.repositionSidebar();
        this.scrollRaf = requestAnimationFrame(this.scheduleDesktopScrollRaf);
    }

    scheduleMobileScrollRaf () {
        this.scrollRaf = requestAnimationFrame(this.scheduleMobileScrollRaf);
    }

    kickOffResizeRaf () {
        if (!this.resizeRaf) {
            this.resizeRaf = requestAnimationFrame(this.scheduleResizeRaf);
        }

        clearTimeout(this.resizeRafTimeout);
        this.resizeRafTimeout = setTimeout(() => {
            cancelAnimationFrame(this.resizeRaf);
            this.resizeRaf = null;
        }, 250);
    }

    scheduleResizeRaf () {
        this.updateMeasurements();
        this.repositionSidebar();
        this.resizeRaf = requestAnimationFrame(this.scheduleResizeRaf);
    }

    repositionSidebar () {
        let position = '';
        let top = '';
        let right = '';

        if (isDesktop()) {
            const mapOffsetTop = this.elemPositions.listingMapInline.top;
            const sidebarHeight = this.elemPositions.sidebar.height;

            // Use .page__row--listing as surrogate for the top and left measurements of .agent-summary in non-fixed state.
            // Measuring .agent-summary while it is fixed will give undesirable results.
            const rowListingTop = this.elemPositions.rowListing.top;
            const rowListingRight = this.elemPositions.rowListing.left + this.elemPositions.rowListing.width;

            const scrolledFarEnoughToBeFixed = pageYOffset >= rowListingTop - SPACING;
            const scrolledFarEnoughToBeBlockedByMap = pageYOffset >= mapOffsetTop - sidebarHeight - (SPACING * 2);

            if (scrolledFarEnoughToBeFixed && !scrolledFarEnoughToBeBlockedByMap) {
                position = 'fixed';
                top = SPACING;
                right = document.body.clientWidth - rowListingRight + SPACING;
            } else if (scrolledFarEnoughToBeBlockedByMap) {
                position = 'absolute';
                top = mapOffsetTop - rowListingTop - sidebarHeight - (SPACING * 2);
            }
        }

        this.sidebar.style.position = position;
        this.sidebar.style.top = top ? `${top}px` : '';
        this.sidebar.style.right = right ? `${right}px` : '';
    }

    updateMeasurements () {
        const sidebarOffsets = offsetRelativeToAncestor(this.sidebarAside, document.body);
        const listingMapInlineOffsets = offsetRelativeToAncestor(this.listingMapInline, document.body);
        const rowListingOffsets = offsetRelativeToAncestor(this.rowListing, document.body);

        this.elemPositions = {
            rowListing: {
                top: rowListingOffsets.top,
                left: rowListingOffsets.left,
                width: this.rowListing.clientWidth,
            },
            sidebar: {
                top: sidebarOffsets.top,
                height: this.sidebar.clientHeight,
            },
            listingMapInline: {
                top: listingMapInlineOffsets.top,
                height: this.listingMapInline.clientHeight,
            },
        };
    }

    onNotifyMeClick () {
        this.messageBus.postMessage({
            'message': 'requestPropertyAlertWizardDialog',
        });
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS.concat(super.messageHandlers);
    }
}

export default {
    'name': NAME,
    'controller': ListingDetailPage,
};
