import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne, offsetRelativeToAncestor } from 'Shared/Helper/Dom/Dom';
import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';

const NAME = 'search-controls';

const CLASSES = {
    'sticky': renderClasses(NAME, null, ['sticky']),
    'otherControls': renderClasses(NAME, 'other-controls'),
    'mailAlertButton': renderClasses(NAME, 'button', ['mail-alert']),
};

const SELECTORS = {
    'otherControls': `.${CLASSES.otherControls}`,
    'mailAlertButton': `.${CLASSES.mailAlertButton}`,
};

const MESSAGES = {
    'searchFiltersButtonClicked': 'searchFiltersButtonClicked',
    'mailAlertButtonClicked': 'mailAlertButtonClicked',
    'mailAlertLinkClicked': 'mailAlertLinkClicked',
};

const MESSAGE_HANDLERS = [
    'searchFiltersOpened.all',
    'searchFiltersClosed.all',
];

class SearchControls extends ComponentController {
    initialize () {
        super.initialize();

        this.bindMethodsToSelf([
            'onScroll',
            'onMailAlertButtonClick',
            'positionOtherControls',
            'scheduleRaf',
            'cancelRaf',
        ]);

        this.searchFiltersOpened = false;
    }

    connect () {
        super.connect();

        window.addEventListener('scroll', this.onScroll);
    }

    onScroll () {
        if (!this.raf) {
            this.scheduleRaf();
        }

        clearTimeout(this.rafTimeout);
        this.rafTimeout = setTimeout(this.cancelRaf, 250);
    }

    onMailAlertButtonClick () {
        this.messageBus.postMessage({
            'message': MESSAGES.mailAlertButtonClicked,
        });
        this.trackMailAlertButtonClick();
    }

    onMailAlertLinkClick () {
        this.messageBus.postMessage({
            'message': MESSAGES.mailAlertLinkClicked,
            'data': {
                'serpUrl': window.location.pathname,
            },
        });
    }

    trackMailAlertButtonClick () {
        const buttonId = 'searchControlsSavePropertyAlertButton';
        const formData = new FormData();
        const url = '/api/track/property-alert-button-click';
        const mailAlertButton = findOne(SELECTORS.mailAlertButton, this.element);

        formData.append('buttonId', buttonId);
        formData.append('mailAlertButtonLabel', mailAlertButton.innerText);

        navigator.sendBeacon(url, formData);
    }

    scheduleRaf () {
        this.raf = requestAnimationFrame(this.positionOtherControls);
    }

    cancelRaf () {
        cancelAnimationFrame(this.raf);
        this.raf = null;
    }

    onSearchFiltersOpened () {
        this.searchFiltersOpened = true;
    }

    onSearchFiltersClosed () {
        this.searchFiltersOpened = false;
    }

    positionOtherControls () {
        const searchControlsTop = offsetRelativeToAncestor(this.element, document.body).top;
        const searchControlsBottom = searchControlsTop + this.element.offsetHeight;
        const otherControls = findOne(SELECTORS.otherControls, this.element);
        const otherControlsHeight = otherControls.offsetHeight;
        const otherControlsTop = searchControlsBottom - otherControlsHeight;
        const scrollTop = window.pageYOffset;

        if (scrollTop > otherControlsTop) {
            this.element.classList.add(CLASSES.sticky);
            otherControls.style.height = `${otherControlsHeight}px`;
        } else {
            this.element.classList.remove(CLASSES.sticky);
            otherControls.style.height = null;
        }

        this.scheduleRaf();
    }

    onSearchFiltersButtonClick () {
        if (!this.searchFiltersOpened) {
            this.messageBus.postMessage({
                'message': MESSAGES.searchFiltersButtonClicked,
            });
        }
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    'name': NAME,
    'controller': SearchControls,
};
