import { findAll } from 'Shared/Helper/Dom/Dom';
import { throwError } from 'Shared/Helper/Error/Error';

const responseCache = {};
const instances = [];

function removeLightbox (lightbox) {
    if (lightbox.parentNode) {
        lightbox.parentNode.removeChild(lightbox);
    }

    if (instances.indexOf(lightbox) > -1) {
        instances.splice(instances.indexOf(lightbox), 1);
    }
}

function addLightbox (html) {
    instances.forEach(removeLightbox);

    // Because we use a web component implementation and a big bundle implementation of LightboxManager
    // the lightboxes can be stored in either one of them and they will not be cleaned up properly because
    // they do not share the same cache.
    // So, for now brute force the removal of other lightboxes
    findAll('.lightbox').forEach(removeLightbox);

    const dummy = document.createElement('div');
    dummy.innerHTML = html;

    // Manually import scripts as they are not automatically loaded when they come from a HTML snippet
    const extractedScripts = findAll('script', dummy);
    const importPromises = extractedScripts.map(script => {
        const parent = script.parentNode;
        parent.removeChild(script);

        if (script.src) {
            return import(script.src);
        }

        const scriptElement = document.createElement('script');
        scriptElement.innerHTML = script.innerHTML;
        parent.appendChild(scriptElement);
    });

    const extractedLinks = findAll('link[rel="stylesheet"]', dummy);
    extractedLinks.forEach(link => {
        const parent = link.parentNode;
        parent.removeChild(link);

        const linkElement = document.createElement('link');
        linkElement.rel = 'stylesheet';
        linkElement.href = link.href;
        document.head.appendChild(linkElement);
    });

    // After all the scripts are loaded place the HTML in the body
    Promise.all(importPromises).finally(() => {
        const lightbox = dummy.removeChild(dummy.children[0]);
        document.body.appendChild(lightbox);

        instances.push(lightbox);

        document.body.style.overflow = 'hidden';
        document.body.style.height = '100%';
        document.documentElement.style.overflow = 'hidden';
        document.documentElement.style.height = '100%';
    });
}

function addFeatureTogglesToUrl (url) {
    const parsedUrl = new URL(url, `https://${document.location.host}`);
    const searchParams = new URLSearchParams(parsedUrl.search);

    const featureToggles = JSON.parse(Platform.enabled_feature_toggles);
    featureToggles.forEach(toggle => {
        searchParams.append('features_enabled[]', toggle);
    });

    return `${parsedUrl.pathname}?${searchParams.toString()}`;
}

function displayLightBox (url, transform) {
    url = addFeatureTogglesToUrl(url);

    responseCache[url] = responseCache[url] || fetch(url);

    responseCache[url]
        .then(response => {
            if (!response.ok) {
                throwError(`Lightbox controller error for listing with url ${url} (status: ${response.status})`);
            }
            return response.clone();
        })
        .then(clonedResponse => {
            return clonedResponse.text();
        })
        .then(html => {
            if (transform) {
                html = transform(html);
            }

            addLightbox(html);
        })
        .catch(e => {
            responseCache[url] = undefined;
            throwError(e);
        });
}

function dismissLightbox (lightbox) {
    removeLightbox(lightbox);

    document.body.style.overflow = '';
    document.body.style.height = '';
    document.documentElement.style.overflow = '';
    document.documentElement.style.height = '';
}

export { displayLightBox, dismissLightbox };
