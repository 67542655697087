import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne, offsetRelativeToAncestor } from 'Shared/Helper/Dom/Dom';
import { isDesktop } from 'Shared/Helper/ViewPort/ViewPort';

import { SharedPageController } from 'Pararius/Page/Page';

const PARENT_NAME = 'page';
const NAME = 'listing-detail';
const SPACING = 16;

const CLASSES = {
    'sidebar': renderClasses(PARENT_NAME, 'sidebar'),
    'rowListing': renderClasses(PARENT_NAME, 'row', ['listing']),
};

const SELECTORS = {
    'sidebar': `.${CLASSES.sidebar}`,
    'rowListing': `.${CLASSES.rowListing}`,
};

class ListingDetailController extends SharedPageController {
    initialize () {
        this.bindMethodsToSelf([
            'kickOffScrollRaf',
            'kickOffResizeRaf',
            'scheduleDesktopScrollRaf',
            'scheduleMobileScrollRaf',
            'scheduleResizeRaf',
            'updateMeasurements',
        ]);

        super.initialize();
    }

    connect () {
        super.connect();

        this.sidebar = findOne(SELECTORS.sidebar, this.element);
        this.rowListing = findOne(SELECTORS.rowListing, this.element);

        this.updateMeasurements();

        window.addEventListener('scroll', this.kickOffScrollRaf);
        window.addEventListener('resize', this.kickOffResizeRaf);
    }

    kickOffScrollRaf () {
        if (!this.scrollRaf) {
            if (isDesktop()) {
                this.scrollRaf = requestAnimationFrame(this.scheduleDesktopScrollRaf);
            } else {
                this.scrollRaf = requestAnimationFrame(this.scheduleMobileScrollRaf);
            }
        }

        clearTimeout(this.scrollRafTimeout);
        this.scrollRafTimeout = setTimeout(() => {
            cancelAnimationFrame(this.scrollRaf);
            this.scrollRaf = null;
        }, 250);
    }

    scheduleDesktopScrollRaf () {
        this.updateMeasurements();
        this.repositionSidebar();
        this.scrollRaf = requestAnimationFrame(this.scheduleDesktopScrollRaf);
    }

    scheduleMobileScrollRaf () {
        this.scrollRaf = requestAnimationFrame(this.scheduleMobileScrollRaf);
    }

    kickOffResizeRaf () {
        if (!this.resizeRaf) {
            this.resizeRaf = requestAnimationFrame(this.scheduleResizeRaf);
        }

        clearTimeout(this.resizeRafTimeout);
        this.resizeRafTimeout = setTimeout(() => {
            cancelAnimationFrame(this.resizeRaf);
            this.resizeRaf = null;
        }, 250);
    }

    scheduleResizeRaf () {
        this.updateMeasurements();
        this.repositionSidebar();
        this.resizeRaf = requestAnimationFrame(this.scheduleResizeRaf);
    }

    repositionSidebar () {
        let position = '';
        let top = '';
        let right = '';

        if (isDesktop()) {
            const sidebarHeight = this.elemPositions.sidebar.height;

            // Use .page__row--listing as surrogate for the top and left measurements of .agent-summary in non-fixed state.
            // Measuring .agent-summary while it is fixed will give undesirable results.
            const rowListingTop = this.elemPositions.rowListing.top;
            const rowListingBottom = this.elemPositions.rowListing.bottom;
            const rowListingRight = this.elemPositions.rowListing.left + this.elemPositions.rowListing.width;

            const scrolledFarEnoughToBeFixed = scrollY >= rowListingTop - SPACING;
            const scrolledFarEnoughToBeBlocked = scrollY >= rowListingBottom - sidebarHeight - (SPACING * 6);

            if (scrolledFarEnoughToBeFixed && !scrolledFarEnoughToBeBlocked) {
                position = 'fixed';
                top = SPACING;
                right = document.body.clientWidth - rowListingRight + SPACING;
            } else if (scrolledFarEnoughToBeBlocked) {
                position = 'absolute';
                top = rowListingBottom - rowListingTop - sidebarHeight - (SPACING * 5);
            }
        }

        this.sidebar.style.position = position;
        this.sidebar.style.top = top ? `${top}px` : '';
        this.sidebar.style.right = right ? `${right}px` : '';
    }

    updateMeasurements () {
        const sidebarOffsets = offsetRelativeToAncestor(this.sidebarAside, document.body);
        const rowListingOffsets = offsetRelativeToAncestor(this.rowListing, document.body);

        this.elemPositions = {
            rowListing: {
                top: rowListingOffsets.top,
                bottom: rowListingOffsets.top + this.rowListing.clientHeight,
                left: rowListingOffsets.left,
                width: this.rowListing.clientWidth,
            },
            sidebar: {
                top: sidebarOffsets.top,
                height: this.sidebar.clientHeight,
            },
        };
    }

    get componentName () {
        return NAME;
    }
}

export default {
    'name': NAME,
    'controller': ListingDetailController,
};
