import { Dialog } from 'Shared/Component/Dialog/Dialog';
import { findAll, hasParent } from 'Shared/Helper/Dom/Dom';

const NAME = 'price-transparency-dialog';

const MESSAGE_HANDLERS = [
    'priceTransparencyBadgeInfoButtonClicked.all',
];

class PriceTransparencyDialog extends Dialog {
    initialize () {
        this.bindMethodsToSelf([
            'onClose',
            'onCancelClick',
            'onBodyClick',
        ]);

        super.initialize();
    }

    connect () {
        super.connect();

        this.element.addEventListener('close', this.onClose);

        const buttons = findAll('button', this.element);
        buttons.forEach(button => button.addEventListener('click', this.onCancelClick));
    }

    onBodyClick (evt) {
        if (!hasParent(evt.target, this.element)) {
            this.element.close();
        }
    }

    onCancelClick () {
        this.element.close();
    }

    onClose () {
        document.body.removeEventListener('click', this.onBodyClick);
        document.body.classList.remove('page--dialog-open');
    }

    onPriceTransparencyBadgeInfoButtonClicked (evt) {
        if (evt.data.data.id === this.element.getAttribute('data-id')) {
            this.element.showModal();
            document.body.classList.add('page--dialog-open');
            document.body.addEventListener('click', this.onBodyClick);
        }
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    'name': NAME,
    'controller': PriceTransparencyDialog,
};
